<template>
  <div class="animated fadeIn">
    <CRow>
      <el-form ref="queryForm" :model="queryForm" label-width="100px" :inline="true">
        <el-form-item label="關鍵字">
          <el-input v-model="queryForm.qstr" placeholder="名稱、備註"></el-input>
        </el-form-item>
         <el-form-item label="標籤">
            <el-select v-model="queryForm.tags" clearable multiple filterable placeholder="請選擇">
              <el-option
                v-for="tag in tags"
                :key="tag.id"
                :label="tag.name"
                :value="tag.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="currentPage=1;query(pageSize,0, sort, order, queryForm)">查詢</el-button>
          <el-button type="primary" icon="el-icon-plus" @click="addNewOrg">新增</el-button>
        </el-form-item>
      </el-form>

    </CRow>
    <CRow>
    <el-table
            :data="organizations"
            style="width:100%"
            border
            stripe
            empty-text="暫無數據"
            :default-sort = "{prop: 'dateCreated', order: 'descending'}"
             @sort-change = "sortChange"
            :cell-class-name="tableCellClassName"
          >
           <el-table-column
              type="index"
              :index="indexFormatter"
              fixed
              >
            </el-table-column>
   
            <el-table-column
                label="名稱"
                prop="name"
                sortable="custom"
            >
            </el-table-column>
              <el-table-column
                label="標籤"
            >
             <template slot-scope="scope">
               <div class="tag_group">   
                     <el-tag v-for="tag in scope.row.tags" :key="tag.id" :type="tag.type">{{tag.name}}</el-tag>
               </div>
             </template>
            </el-table-column>
            <el-table-column
                label="生效日期"
                prop="start_date"
                :formatter="dateFormatter"
                width="150px"
                 sortable="custom"
            >
            </el-table-column>
            <el-table-column
                label="失效日期"
                prop="end_date"
                :formatter="dateFormatter"
                width="150px"
                 sortable="custom"
            >
            </el-table-column>
            <el-table-column
                label="備註"
                prop="memo"
                 sortable="custom"
            >
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="150">
              <template slot-scope="scope">
                <el-button
                  @click.native.prevent="editOrganization(scope.row.id)"
                  
                  size="mini">
                  編輯
                </el-button>
                <el-button
                  @click.native.prevent="deleteOrganization(scope.row.id)"
                  type="danger"
                  size="mini">
                  刪除
                </el-button>
              </template>
          </el-table-column>
          </el-table>

    </CRow>
    <CRow>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-sizes="[10, 25, 50]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
    </CRow>
      <el-dialog title="新增單位" :visible.sync="dialogFormVisible">
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item label="名稱" :label-width="formLabelWidth" prop="name">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="標籤" class="tags" :label-width="formLabelWidth" prop="tags">
          <div style="all:initial;">
              <el-transfer
                v-model="form.tags"
                filterable
                :titles="['候選', '已加入']"
                :props="{
                  key: 'id',
                  label: 'name'
                }"
                :data="tags">
                <span slot-scope="{ option }"><el-tag :type="option.type">{{ option.name }}</el-tag></span>
              </el-transfer>
          </div>
        </el-form-item>
        <el-form-item label="生效日期" :label-width="formLabelWidth" prop="start_date">
          <el-date-picker type="date" value-format="timestamp" placeholder="選擇日期"  v-model="form.start_date"></el-date-picker>
        </el-form-item>
        <el-form-item label="失效日期" :label-width="formLabelWidth">
          <el-date-picker type="date" value-format="timestamp" placeholder="選擇日期"  v-model="form.end_date"></el-date-picker>
        </el-form-item>
          <el-form-item label="備註" :label-width="formLabelWidth">
            <el-input type="textarea" v-model="form.memo"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">確 定</el-button>
      </div>
  </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Vue from 'vue'
import moment from "moment-timezone";
import API from '@/api/'
Vue.filter('converDate',function(value){
  if(value){
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment(value).tz(tz).format('YYYY-MM-DD');
  }else{
    return value;
  }

})
export default {
  name: 'OrganizationList',
  created () {
      this.$store.dispatch('tag/list', {max: 1000, offset: 0}, {
          root: true
      });
      this.$store.dispatch('organization/list', {max: this.max, offset: this.offset, sort: this.sort, order: this.order , showExp: true}, {
          root: true
      });
  },
  data() {
        var validateName = (rule, value, callback) => {
            if (this.form.id) {
                 const data = this.getOrganizationById(this.form.id);
                 if ( data.name === value) {
                   callback();
                   return;
                 }
            } 
            API.organization.search({name: value}).then(res =>{
              if (res.data.length == 0) {
                  callback();
              } else {
                callback(new Error('已存在'));
              } 
            })
        };
        return {
          pageSize:10,
          currentPage:0,
          queryForm: {
            qstr:'',
            tags:[]
          },
          form: {
            id: null,
            name:'',
            memo:'',
            start_date: null,
            end_date: null,
             tags: []
          },
          dialogFormVisible: false,
          formLabelWidth: '120px',
          rules: {
            name: [
              { required: true, message: '請輸入名稱', trigger: 'blur' },
              { validator: validateName, trigger: 'blur' },
            ],
            start_date: [
              { required: true, message: '請輸生效日期', trigger: 'blur' },
            ],
          },
        }
  },
  methods:{
    addNewOrg() {
      if (this.$refs['form']) {
        this.$refs['form'].resetFields();
      }
      this.$store.dispatch('tag/list', {max: 100, offset: 0}, {
          root: true
      });
      this.form.id = null;
      this.form.name = '';
      this.form.memo = '';
      this.form.start_date = moment().startOf('day').toDate().getTime();
      this.form.end_date = null;
      this.form.tags = [];
      
      this.dialogFormVisible = true;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.query(val,this.offset, this.sort, this.order, this.queryForm);
      },
      handleCurrentChange(val) {
        this.query(this.pageSize,this.pageSize*(val-1), this.sort, this.order, this.queryForm);

      },
    query(max,offset,sort, order, param = {}) {
      this.$store.dispatch('organization/list', {max: max, offset: offset, sort, order, showExp: true, ...param}, {
          root: true
      });
    },
    indexFormatter(index){
     return `#${parseInt(this.offset)+ index + 1}`;

    },
    dateFormatter(row, column, cellValue){
      if(cellValue){
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

        return moment(parseInt(cellValue)).tz(tz).format('YYYY-MM-DD');
        //return moment(cellValue).format('YYYY-MM-DD');
      }else{
        return cellValue;
      }
    },
    submit() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
              if(this.form.id) {
                this.submitUpdate();
              } else {
                this.submitAdd();
              }
          } else {
            return false;
          }
        });
    },
    submitAdd() {
        this.$store.dispatch('organization/add', this.form, {
          root: true
        }).then( res => {
        this.dialogFormVisible = false;
        this.form.id=null;
        if (this.$refs['form']) {
        this.$refs['form'].resetFields();
      }
        this.query(this.pageSize,this.offset, this.sort, this.order, this.queryForm)
      })
    },
    submitUpdate() {
      this.$store.dispatch('organization/update', this.form , {
          root: true
      }).then( res => {
        this.dialogFormVisible = false;
        this.form.id=null;
        if (this.$refs['form']) {
        this.$refs['form'].resetFields();
      }
        this.query(this.pageSize,this.offset, this.sort, this.order, this.queryForm)
      })
    },
    editOrganization(organization_id) {
      if (this.$refs['form']) {
        this.$refs['form'].resetFields();
      }
      this.$store.dispatch('tag/list', {max: 1000, offset: 0} , {
          root: true
      }).then( res => {
        const data = this.getOrganizationById(organization_id);
        this.form.id=data.id;
        this.form.name=data.name;
        this.form.memo=data.memo;
        this.form.tags=data.tags.map(tag=>tag.id);
        this.form.start_date= data.start_date;
        this.form.end_date= data.end_date;
       this.dialogFormVisible = true;
      })
       
    },
    deleteOrganization(organization_id) {
      this.$confirm('此操作將永久刪除該單位, 是否繼續?', '提示', {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$store.dispatch('organization/remove', organization_id , {
            root: true
          }).then( res => {
            this.query(this.pageSize,this.offset, this.sort, this.order, this.queryForm)
            this.$message({
            type: 'success',
            message: '删除成功!'
          });
          })
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });

    },
    sortChange(args) {
      if (args.order === null) {
        this.$store.commit('organization/SET_SORT','id',{root: true})
        this.$store.commit('organization/SET_ORDER','ascending',{root: true})
      } else {
        this.$store.commit('organization/SET_SORT',args.prop,{root: true})
        this.$store.commit('organization/SET_ORDER',args.order,{root: true})
      }
      
      this.query(this.pageSize,this.offset,this.sort,this.order, this.queryForm)
    },
    tableCellClassName({row, column, rowIndex, columnIndex}) {
      const now = new Date().getTime();
      if(column.property === 'start_date'){
        if (row.start_date > now) {
          return 'warning-cell';
        } 
      }
      if(column.property === 'end_date'){
        if(row.end_date < now){
            return 'warning-cell';
        }
      }
      return '';
    },
  },
  computed: {
      ...mapGetters({
          // 取得資料夾列表
          organizations: 'organization/organizations',
          total:'organization/total',
          max:'organization/max',
          offset:'organization/offset',
          getOrganizationById: 'organization/getOrganizationById',
          tags: 'tag/tags',
          sort: 'organization/sort',
          order: 'organization/order'
      })
  },
}
</script>

<style scoped>
  .tag_group .el-tag+.el-tag {
    margin-left: 10px;
  }
  .el-form-item.tags.is-error.is-required >>> .el-input__inner {
    border-bottom-color:  rgb(220, 223, 230);
    border-top-color:  rgb(220, 223, 230);
    border-right-color:  rgb(220, 223, 230);
    border-left-color:  rgb(220, 223, 230);
  }
  .el-form-item.tags.is-error.is-required >>> .el-transfer-panel:last-child {
    border-bottom-color:  rgb(245, 108, 108);
    border-top-color:  rgb(245, 108, 108);
    border-right-color:  rgb(245, 108, 108);
    border-left-color:  rgb(245, 108, 108);
  }
  .el-transfer >>> .el-transfer__buttons {
    width: 100px;
  }
  .el-transfer >>> .el-transfer__buttons .el-button+.el-button {
    margin-left: 0px;
  }
  .el-table >>> .el-table__body td.warning-cell{
    color:#F56C6C;
  }
</style>

